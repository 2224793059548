import * as React from 'react';
import { Link, useTranslation} from 'gatsby-plugin-react-i18next';
import slugify from '@sindresorhus/slugify';
import { StaticImage } from 'gatsby-plugin-image';

const RealisationsFilter = ({ data }) => {
  const {t} = useTranslation();
  return (
    <>
      {data.services.group.length ?
        <div key="services" className="mb-7">
          <div className="mb-3">
            <h3>{t('services')}</h3>
          </div>
          {data.services.group.map(service => (
            <Link key={service.title} to={`/realisations/services/${slugify(service.title)}/`} className="btn btn-soft-secondary btn-xs rounded-pill me-1 mb-1" activeClassName="active">
              {t('realisation.services.' + service.title + '.label')} <span className="badge bg-light rounded-pill text-dark m-1">{service.totalCount}</span>
            </Link>
          ))}
        </div>
      : null}
      {data.categories.group.length ?
        <div key="categories" className="mb-7">
          <div className="mb-3">
            <h3>{t('categories')}</h3>
          </div>
          {data.categories.group.map(category => (
            <Link key={category.title} to={`/realisations/categories/${slugify(category.title)}/`} className="btn btn-soft-secondary btn-xs rounded-pill me-1 mb-1" activeClassName="active">
              {t('realisation.categories.' + category.title + '.label')} <span className="badge bg-light rounded-pill text-dark m-1">{category.totalCount}</span>
            </Link>
          ))}
        </div>
      : null} 
      {data.tags.group.length ?
        <div key="tags" className="mb-7">
          <div className="mb-3">
            <h3>{t('tags')}</h3>
          </div>
          {data.tags.group.map(tag => (
            <Link key={tag.title} to={`/realisations/tags/${slugify(tag.title)}/`} className="btn btn-soft-secondary btn-xs rounded-pill me-1 mb-1" activeClassName="active">
              {t('realisation.tags.' + tag.title + '.label')} <span className="badge bg-light rounded-pill text-dark m-1">{tag.totalCount}</span>
            </Link>
          ))}
        </div>
      : null}
      {data.technologies.group.length ?
        <div key="technologies" className="mb-7">
          <div className="mb-3">
            <h3>{t('technologies')}</h3>
          </div>
          {data.technologies.group.map(technology => (
            <Link key={technology.title} to={`/realisations/technologies/${slugify(technology.title)}/`} className="btn btn-soft-secondary btn-xs rounded-pill me-1 mb-1" activeClassName="active">
              {t('realisation.technologies.' + technology.title + '.label')} <span className="badge bg-light rounded-pill text-dark m-1">{technology.totalCount}</span>
            </Link>
          ))}
        </div>
      : null}
      {data.languages.group.length ?
        <div key="languages" className="mb-7">
          <div className="mb-3">
            <h3>{t('languages')}</h3>
          </div>
          {data.languages.group.map((language, index) => (
            <span key={index}>
              {language.title === 'en-gb' &&
                  <Link key={language.title} to={`/realisations/languages/${slugify(language.title)}/`} className="btn btn-soft-secondary btn-xs rounded-pill me-1 mb-1" activeClassName="active" data-bs-toggle="tooltip" data-bs-container="body" data-bs-placement="top" data-bs-html="true" data-bs-trigger="hover" data-bs-original-title={t('realisation.languages.' + language.title + '.label')}>
                    <StaticImage className="avatar avatar-xss avatar-rounded mt-1 me-2" imgClassName="avatar-img" objectFit="none" quality="30" src={`../../images/flags/locales/en-gb.png`} alt={t('realisation.languages.' + language.title + '.label')} width={20} height={20} /><span className="badge bg-light rounded-pill text-dark m-1">{language.totalCount}</span>
                  </Link>
              } 
              {language.title === 'fr-fr' &&
                  <Link key={language.title} to={`/realisations/languages/${slugify(language.title)}/`} className="btn btn-soft-secondary btn-xs rounded-pill me-1 mb-1" activeClassName="active" data-bs-toggle="tooltip" data-bs-container="body" data-bs-placement="top" data-bs-html="true" data-bs-trigger="hover" data-bs-original-title={t('realisation.languages.' + language.title + '.label')}>
                    <StaticImage className="avatar avatar-xss avatar-rounded mt-1 me-2" imgClassName="avatar-img" objectFit="none" quality="30" src={`../../images/flags/locales/fr-fr.png`} alt={t('realisation.languages.' + language.title + '.label')} width={20} height={20} /><span className="badge bg-light rounded-pill text-dark m-1">{language.totalCount}</span>
                  </Link>
              }
              {language.title === 'ru-ru' &&
                  <Link key={language.title} to={`/realisations/languages/${slugify(language.title)}/`} className="btn btn-soft-secondary btn-xs rounded-pill me-1 mb-1" activeClassName="active" data-bs-toggle="tooltip" data-bs-container="body" data-bs-placement="top" data-bs-html="true" data-bs-trigger="hover" data-bs-original-title={t('realisation.languages.' + language.title + '.label')}>
                    <StaticImage className="avatar avatar-xss avatar-rounded mt-1 me-2" imgClassName="avatar-img" objectFit="none" quality="30" src={`../../images/flags/locales/ru-ru.png`} alt={t('realisation.languages.' + language.title + '.label')} width={20} height={20} /><span className="badge bg-light rounded-pill text-dark m-1">{language.totalCount}</span>
                  </Link>
                
              }
            </span>
          ))}
        </div>
      : null}
    </>
  )
}

export default RealisationsFilter